import axios from 'axios';

import { store } from '../../redux-tool-kit/store';
import { getConfig } from '../../utils/config';
import { OdometerReading } from '../../types';

export interface BasicMetricsPayload {
  odometer: OdometerReading;
  fuelPercentage: number;
}

export const persistMetrics = (vid: string, payload: BasicMetricsPayload) => {
  payload.fuelPercentage *= 100; // API expects float in range [0, 100]
  const jwt = store.getState().user.jwt;

  return axios({
    method: 'POST',
    url: `${getConfig().superedge}${getConfig().vehicleMetrics}&fleetVehicleId=${vid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
      Accept: 'application/json',
    },
    data: payload,
  });
};
