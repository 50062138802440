import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux-tool-kit/hooks';
import { useTranslation } from 'react-i18next';
import { capitalize, partialRight } from 'lodash';
import {
  AddConditionDetailsBox,
  AddConditionHeader,
  ConditionLabel,
  StyledConditionModal,
} from '../../../styles/screens/StyledConditionModal';
import { BasicVehicleData } from '../../../shared/Vehicle/BasicVehicleData';
import { SingleFlatFish } from '../../../shared/images/Flatfish/SingleFlatFish';
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ValidatedTextField from '../../../shared/ValidatedTextField/ValidatedTextField';
import { initialStateSingle, VehicleComponent } from '../../../types/claim/vehicle/VehicleComponents';
import { activeComponentData, setActiveComponent } from '../../../redux-tool-kit/activeComponentSlice';
import { addNewCondition } from '../../../redux-tool-kit/conditionSlice';
import {
  getPartNameFromURN,
  markComponent,
  paintCurrentComponents,
  unMarkComponent,
} from '../../../utils/flatfish/FlatfishUtils';
import { useAppNavigation } from '../../../utils/useURLs';
import { StyledModalAddConditionContainer } from '../../../styles/screens/StyledViewCondition';
import VehicleConditionsList from '../conditionLists/VehicleConditionsList';
import { mostSignificant } from '../../../utils/urn';
import { showSnackBarAlert } from '../../../utils/snackBar/snackBarAlert';
import { getFlatfishViewFromUrl, useGetConditions } from '../../../utils/condition/ConditionHelper';
import ImageWidget from '../../../shared/images/ImageWidget';
import type { ConditionImage } from '../../../types';
import { uploadConditionImage } from '../../../services/Images/ImageService';
import {
  StyledDate,
  StyledImagePreviewContainer,
  StyledSubHeading,
} from '../../../styles/shared/Vehicle/StyledImagePreview';
import { setImgElemStyle } from '../../../utils/condition/ImageDisplayHelper';
import { ImageCarousel } from '../../../shared/images/ImageCarousel/ImageCarousel';
import TemporaryImage from '../../../shared/images/ImageCapture/TemporaryImage';
import useConditionType from '../../../hooks/useConditionType';
import useAppParams from '../../../hooks/useAppParams';
import useVehicle from '../../../hooks/useVehicle';
import useComponent from '../../../hooks/useComponent';
import { getConfig } from '../../../utils/config';
import PendoTag from '../../../shared/PendoTag';
import { trackPendoEvent } from '@ehi/analytics';
import { APP_NAME } from '../../../shared/constants';
import { sortByDeburred } from '../../../utils/language';

const AddCondition = () => {
  const { data: vehicleComponents = [] } = useComponent();
  const activeComponent: VehicleComponent = useAppSelector(activeComponentData);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const [selectedConditionType, setSelectedConditionType] = useState<string>('');
  const [selectedDamageType, setSelectedDamageType] = useState<string>('');
  const [conditionLocation, setConditionLocation] = useState<string>('');
  const [conditionComment, setConditionComment] = useState<string>('');
  const [selectedPathIndicator, setSelectedPathIndicator] = useState<SVGUseElement | null>(null);
  const [localIndicators, setLocalIndicators] = useState(document.querySelectorAll('use'));
  const [images, setImages] = useState<ConditionImage[]>([]);
  const [showCarousel, setShowCarousel] = useState(false);
  const [initialImageSelection, setInitialImageSelection] = useState(0);
  const [temporaryImage, setTemporaryImage] = useState<string | null>(null);
  const { schematic } = useAppParams();
  const getConditions = useGetConditions();
  const { data: conditionTypes } = useConditionType();
  const { data: vehicle } = useVehicle();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  function getSVGHeight() {
    if (schematic !== 'FRONT' && schematic !== 'BACK' && lessThanSmall) {
      return 100;
    }
    return 320;
  }

  const markComponentStdColors = partialRight(
    markComponent,
    selectedDamageType === 'Wear and Tear' ? '#632289' : '#FFA100'
  );

  useEffect(() => {
    if (selectedPathIndicator) markComponentStdColors(selectedPathIndicator);
  }, [selectedDamageType]);

  const addImage = async (image: string) => {
    if (!vehicle) {
      throw new Error('No Vehicle');
    }
    setTemporaryImage(image);
    const response = await uploadConditionImage(image, vehicle.vehicleAsset.vin);
    setTemporaryImage(null);

    if (response.success) {
      setImages([...images, response.data]);
    }
  };

  const completeAddCondition = () => {
    const isClaimable = selectedDamageType !== 'Wear and Tear';
    dispatch(
      addNewCondition({
        images: images,
        component: activeComponent.urn,
        conditionType: selectedConditionType,
        isClaimable,
        comment: `${conditionLocation} ${conditionComment}`,
      })
    );
  };

  const clearForm = () => {
    if (selectedPathIndicator) {
      unMarkComponent(selectedPathIndicator);
      setSelectedPathIndicator(null);
    }
    dispatch(setActiveComponent(initialStateSingle));
    setSelectedDamageType('');
    setSelectedConditionType('');
    setConditionLocation('');
    setConditionComment('');
    setImages([]);
  };

  const handleLocationSelectChange = (event: SelectChangeEvent) => {
    setConditionLocation(event.target.value);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventTarget = event.target.value;

    if (eventTarget !== conditionLocation) {
      setConditionComment(eventTarget.replace(`${conditionLocation} `, ''));
    }
  };

  const changeConditionLocation = (name: string) => {
    const currentComponent = vehicleComponents.find((comp) => comp.name === name);
    if (currentComponent) {
      dispatch(setActiveComponent(currentComponent));
      const currentComponentIndicator = Array.from(localIndicators).find(
        (path) => path.id.substring(0, path.id.length - 1) === getPartNameFromURN(currentComponent.urn)
      );
      if (currentComponentIndicator) {
        markComponentStdColors(currentComponentIndicator);
        if (selectedPathIndicator && selectedPathIndicator !== currentComponentIndicator)
          unMarkComponent(selectedPathIndicator);
        setSelectedPathIndicator(currentComponentIndicator);
      }
    }
  };

  const onSVGClick = (e: React.MouseEvent) => {
    const target = e.target as SVGPathElement;
    const targetID = target.id.substring(0, target.id.length - 1);
    const currentComponent = vehicleComponents.find((comp) => {
      return targetID === getPartNameFromURN(comp.urn);
    });
    const targetIndicator = Array.from(localIndicators).find((icon) => {
      return targetID === icon.id.substring(0, icon.id.length - 1);
    });
    if (currentComponent) dispatch(setActiveComponent(currentComponent));
    if (selectedPathIndicator) {
      unMarkComponent(selectedPathIndicator);
      setSelectedPathIndicator(null);
      if (targetIndicator && selectedPathIndicator !== targetIndicator) {
        markComponentStdColors(targetIndicator);
        paintCurrentComponents(localIndicators, getConditions);
        setSelectedPathIndicator(targetIndicator);
      }
    }
    if (targetIndicator && !selectedPathIndicator) {
      markComponentStdColors(targetIndicator);
      setSelectedPathIndicator(targetIndicator);
    }
  };

  const formatConditionType = (typeUrn: string): string =>
    conditionTypes?.[typeUrn] ?? capitalize(mostSignificant(typeUrn));

  const isFormValid = () =>
    !!activeComponent.name && !!selectedDamageType && !!selectedConditionType && !temporaryImage;

  const surfacePendoTrackEvent = () => {
    trackPendoEvent(APP_NAME, 'add condition', {
      docMethod: schematic === 'NONE' ? 'list' : 'schematic',
      damageType: selectedDamageType,
      conditionType: formatConditionType(selectedConditionType),
      conditionLocation: activeComponent.name,
      locationDetail: conditionLocation, // NOTE: this will change once "relative conditions" are added.
    });
  };

  return (
    <StyledConditionModal id='addConditionView'>
      <Grid container direction='row' columnSpacing={1} padding={2} paddingBottom={1}>
        <Grid item xs={12} className='header' data-testid='condition-view-title'>
          {t('addCondScr.addCondition')}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12} sm={12} data-testid={'vehicle-data'}>
          <BasicVehicleData hideHeader />
        </Grid>
        <Grid sx={{ position: 'relative' }} item xs={12} sm={12} data-testid={'vehicle-image'}>
          {schematic !== 'NONE' && (
            <SingleFlatFish
              data-testid={schematic}
              imageUrn={`urn:com.ehi:${getConfig().environment}:reference:vehicle:condition:position:${schematic}`}
              imageHeight={getSVGHeight()}
              onSVGClick={onSVGClick}
              setLocalIndicators={setLocalIndicators}
            />
          )}
        </Grid>
      </Grid>
      <div style={{ padding: '15px' }}>
        <AddConditionDetailsBox data-testid={'AddConditionDetails'}>
          <Box>
            <AddConditionHeader sx={{ float: 'left' }}>{t('condition.view.newConditionDetails')}</AddConditionHeader>
            <Button aria-label='more' sx={{ float: 'right' }} onClick={clearForm}>
              {t('clearAll')}
            </Button>
          </Box>
          <Grid container rowSpacing={1} columnSpacing={1} alignItems='row'>
            <Grid item xs={12} md={6} sm={12}>
              <ConditionLabel>{t('location')}</ConditionLabel>
              <FormControl fullWidth>
                <InputLabel>{t('condition.view.labels.location')}</InputLabel>
                <Select
                  label={t('condition.view.labels.location')}
                  value={activeComponent.name}
                  sx={{ marginBottom: '10px' }}
                  data-testid={'condition-component-select'}
                  onChange={(e) => changeConditionLocation(e.target.value)}>
                  {sortByDeburred(vehicleComponents, ({ name }) => name).map((comp: VehicleComponent) => (
                    <MenuItem key={comp.urn} value={comp.name}>
                      {comp.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <ConditionLabel>{t('type')}</ConditionLabel>
              <FormControl>
                <RadioGroup
                  row
                  value={selectedDamageType}
                  onChange={(e) => {
                    setSelectedDamageType(e.target.value);
                  }}>
                  <FormControlLabel label={t('damage')} control={<Radio />} value='Damage' />
                  <FormControlLabel label={t('wearAndTear')} control={<Radio />} value='Wear and Tear' />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t('condition.view.labels.locationDetail')}</InputLabel>
                <Select
                  data-testid='condition-location-select'
                  value={conditionLocation}
                  label={t('condition.view.labels.locationDetail')}
                  onChange={handleLocationSelectChange}>
                  <MenuItem value={'None'}>{t('none')}</MenuItem>
                  <MenuItem value={t('condition.capture.locationLabels.topLeft')}>
                    {t('condition.capture.locationLabels.topLeft')}
                  </MenuItem>
                  <MenuItem value={t('condition.capture.locationLabels.topRight')}>
                    {t('condition.capture.locationLabels.topRight')}
                  </MenuItem>
                  <MenuItem value={t('condition.capture.locationLabels.bottomLeft')}>
                    {t('condition.capture.locationLabels.bottomLeft')}
                  </MenuItem>
                  <MenuItem value={t('condition.capture.locationLabels.bottomRight')}>
                    {t('condition.capture.locationLabels.bottomRight')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{ marginBottom: '30px' }} item xs={12} md={6} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t('condition.view.labels.type')}</InputLabel>
                <Select
                  data-testid={'condition-type-select'}
                  value={selectedConditionType}
                  onChange={(e) => setSelectedConditionType(e.target.value)}
                  label={t('condition.view.labels.type')}>
                  {sortByDeburred(activeComponent.applicableConditionTypes, (conditionType) =>
                    conditionType.toLowerCase()
                  ).map((typeUrn) => (
                    <MenuItem key={typeUrn} value={typeUrn}>
                      {formatConditionType(typeUrn)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <ValidatedTextField
                showAdornment={true}
                label={t('condition.view.labels.notes')}
                multiline
                minRows={3}
                maxLength={150}
                count={
                  conditionLocation === 'None'
                    ? conditionComment.length
                    : conditionLocation.length + conditionComment.length + 1
                }
                value={conditionLocation === 'None' ? conditionComment : `${conditionLocation} ${conditionComment}`}
                onChange={handleCommentChange}
              />
            </Grid>
            <StyledImagePreviewContainer>
              <Grid item container spacing={1.5} xs={12}>
                {images.map((img, idx) => (
                  <Grid item key={img.dateTime} data-testid={img.dateTime}>
                    <Box
                      sx={setImgElemStyle(img)}
                      onClick={() => {
                        setInitialImageSelection(idx);
                        setShowCarousel(true);
                      }}
                    />
                    <StyledSubHeading>{t('takenOn')}</StyledSubHeading>
                    <StyledDate>
                      <b>{t('intlImageDate', { date: new Date(img.dateTime) })}</b>
                      {t('intlImageTime', { date: new Date(img.dateTime) })}
                    </StyledDate>
                  </Grid>
                ))}
                <Grid item>{temporaryImage ? <TemporaryImage bytes={temporaryImage} /> : null}</Grid>
                <ImageWidget onUpload={addImage} isLoading={!!temporaryImage} />
              </Grid>
            </StyledImagePreviewContainer>
          </Grid>
        </AddConditionDetailsBox>
      </div>
      <StyledModalAddConditionContainer
        style={{
          backgroundColor: '#F1F4F7',
        }}>
        {getConditions(true) ? (
          <>
            <Divider />
            <Typography
              sx={{
                marginLeft: '20px',
                marginBottom: '10px',
                fontSize: '20px',
                marginTop: '10px',
                color: 'black',
              }}>
              <strong>{t('condition.view.labels.modalDamage')}</strong>
            </Typography>
            <VehicleConditionsList
              isModal={true}
              conditions={getConditions(true)}
              data-testid='vehicle-condition-damages'
              claimable={true}
            />
          </>
        ) : null}
        {getConditions(false) ? (
          <>
            <VehicleConditionsList
              isModal={true}
              conditions={getConditions(false)}
              data-testid='vehicle-condition-wearTear'
              claimable={false}
            />
            <Divider />
          </>
        ) : null}
      </StyledModalAddConditionContainer>
      <Grid
        item
        xs={12}
        padding={3}
        spacing={3}
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'>
        <Grid item>
          <Button
            variant='text'
            className='bold ehi-blue'
            data-testid={'cancel-button'}
            onClick={() => {
              navigation.push('/capture');
              clearForm();
            }}>
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <PendoTag tag={`pendo-submit-and-add-more-${getFlatfishViewFromUrl()}`}>
            <Button
              disabled={!isFormValid()}
              className='bold ehi-blue ehi-gray-background'
              variant='contained'
              onClick={() => {
                completeAddCondition();
                showSnackBarAlert(t('conditionCapScr.newConditionSuccessfullyAdded'));
                surfacePendoTrackEvent();
                setSelectedPathIndicator(null);
                clearForm();
                setImages([]);
              }}>
              {t('addCondScr.submitAndAddMore')}
            </Button>
          </PendoTag>
        </Grid>
        <Grid item>
          <PendoTag tag={`pendo-submit-condition-${getFlatfishViewFromUrl()}`}>
            <Button
              variant='contained'
              className='active-button'
              data-testid={'save-button'}
              disabled={!isFormValid()}
              onClick={() => {
                completeAddCondition();
                showSnackBarAlert(t('conditionCapScr.newConditionSuccessfullyAdded'));
                surfacePendoTrackEvent();
                clearForm();
                navigation.push('/capture');
              }}>
              {t('addCondScr.submitCondition')}
            </Button>
          </PendoTag>
        </Grid>
      </Grid>
      <Dialog open={showCarousel} onClose={() => setShowCarousel(false)}>
        <ImageCarousel
          images={images.map((condImage) => ({
            src: condImage.formattedImages[0].url,
            date: new Date(condImage.dateTime),
          }))}
          initialIndex={initialImageSelection}
          header={t('viewEmployeePhotos')}
        />
        <Divider />
        <Grid container justifyContent='right'>
          <Grid item>
            <Button size='small' variant='contained' onClick={() => setShowCarousel(false)}>
              {t('close')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </StyledConditionModal>
  );
};
export default AddCondition;
