// Copied verbatim from VEM
import { enCA, enGB, enIE, enUS, es, de, fr, frCA } from 'date-fns/locale';
import { deburr, flow, sortBy } from 'lodash';

export const browserLocaleToIso3 = (browserLanguage: string) => {
  switch (browserLanguage) {
    case 'es':
    case 'es-ES':
      return 'spa-ESP';
    case 'fr-CA':
      return 'fra-CAN';
    case 'fr':
    case 'fr-FR':
      return 'fra-FRA';
    case 'de':
    case 'de-DE':
      return 'deu-DEU';
    case 'en-CAN':
      return 'eng-CAN';
    case 'en-GB':
      return 'eng-GBR';
    case 'en-IE':
      return 'eng-IRL';
    default:
      return 'eng-USA';
  }
};

export const iso3LocaleToiso2 = (iso2Locale: string): string => {
  switch (iso2Locale.toLocaleLowerCase()) {
    case 'eng-usa':
      return 'en-us';
    case 'fra-can':
      return 'fr-ca';
    case 'eng-can':
      return 'en-ca';
    case 'fra-fra':
      return 'fr-fr';
    case 'deu-deu':
      return 'de-de';
    case 'esp-esp':
      return 'es-es';
    case 'eng-gbr':
      return 'en-gb';
    case 'eng-irl':
      return 'en-ie';
    default:
      return 'en-us';
  }
};
export const localeLookup = (lang: string): Locale => {
  switch (lang.toLowerCase()) {
    case 'en-ca':
      return enCA;
    case 'en-gb':
      return enGB;
    case 'en ie':
      return enIE;
    case 'es':
    case 'es-es':
      return es;
    case 'de':
    case 'de-de':
      return de;
    case 'fr':
    case 'fr-fr':
      return fr;
    case 'fr-ca':
      return frCA;
    default:
    case 'en-us':
      return enUS;
  }
};

export function sortByDeburred<T>(coll: Array<T>, fn: (arg: T) => string): Array<T> {
  return sortBy(coll, flow([fn, deburr]));
}
